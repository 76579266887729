<template>
  <form @submit.prevent="submit">
    <loading-overlay
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
    >
      <CRow>
          <CCol sm="12" lg="12">
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <CInput
                    :label="`${$t('label.name')} (ES)`"
                    addLabelClasses="required text-right"
                    v-uppercase
                    :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                    :placeholder="`${$t('label.accessTypeName')} (ES)`"
                    v-model="$v.TpAccessNameEs.$model"
                    :is-valid="hasError($v.TpAccessNameEs)"
                    :invalid-feedback="errorMessage($v.TpAccessNameEs)"
                  >
                  </CInput>    
                </CCol>
                <CCol sm="12" lg="12">
                  <CInput
                    :label="$t('label.name')+' '+'(EN)'"
                    addLabelClasses="required text-right"
                    v-uppercase
                    :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                    :placeholder="`${$t('label.accessTypeName')} (EN)`"
                    v-model="$v.TpAccessNameEn.$model"
                    :is-valid="hasError($v.TpAccessNameEn)"
                    :invalid-feedback="errorMessage($v.TpAccessNameEn)"
                  >
                  </CInput>    
                </CCol>
                <CCol sm="12" lg="12" v-if="edit">
                  <CSelect
                    :options="statusOptions"
                    :value.sync="Status"
                    :label="$t('label.status')"
                    :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                    addLabelClasses="required text-right"
                    :is-valid="statusSelectColor"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCol>
      </CRow>
      <template #footer>
          <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="edit ? statusConfirmation(AccessItems.FgActTpAccess, Status, submit) : submit()"
          >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
          <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="$emit('close');"
          >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
          </CButton>
      </template>
    </CModalExtended>
  </form>
</template>

<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import accessValidations from '@/_validations/access-types/accessVal';

function data() {
return {
  isSubmit: false,
  modalActive: false,
  Loading: false,
  TpAccessId: '',
  TpAccessNameEs: '',
  TpAccessNameEn: '',
  Status: 0
};
}

function submit() {
try {
  this.isSubmit = true;
  this.Loading = true;
  this.$v.$touch();
  if (this.$v.$error) {
  this.isSubmit = false;
  this.Loading = false;
  throw this.$t('label.errorsPleaseCheck');
  }
 
  let TpAccessJson = this.edit ? {
    TpAccessId: this.TpAccessId,
    TpAccessNameEs: this.TpAccessNameEs,
    TpAccessNameEn: this.TpAccessNameEn,
    Status: this.Status
  }:{
    TpAccessNameEs: this.TpAccessNameEs,
    TpAccessNameEn: this.TpAccessNameEn,
  };

  let metodo = this.edit ? 'PUT':'POST';
  let ruta = this.edit ? 'TpAccess-update': 'TpAccess-insert';

  this.$http.ejecutar(metodo, ruta, TpAccessJson, { root: 'TpAccessJson' })
  .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: response.data.data[0].Response,
      type: "success"
      });
      this.resetForm();
  }).catch(err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
  });
} catch (error) {
  this.$notify({
  group: 'container',
  title: '¡Error!',
  text: error,
  type: "error"
  });
}

}

function statusSelectColor() {
  return this.Status === 1;
}

function getData(val) {
  this.TpAccessId = val.TpAccessId;
  this.TpAccessNameEs = val.TpAccessNameEs;
  this.TpAccessNameEn = val.TpAccessNameEn;
  this.Status = val.FgActTpAccess ?1:0;

  this.$v.$touch();
}
function resetForm() {
  this.TpAccessId = '';
  this.TpAccessNameEs = '';
  this.TpAccessNameEn = '';
  this.Status = 0;
  
  this.$v.$reset();
}

export default {
name: 'tpAccess-modal',
props: { modal: Boolean, edit: Boolean,title: String, AccessItems: Object},
data,
validations: accessValidations,
mixins: [
  ModalMixin,
],
directives: UpperCase,
methods: {
  statusSelectColor,
  getData,
  submit,
  resetForm,
},
computed: {

},
watch: {
  modal: function (val) {
    this.modalActive = val;
    if(val){
      if (this.edit) {
        this.getData(this.AccessItems);
      }
    }else{
      this.resetForm();
    }
  },
},

};
</script>
